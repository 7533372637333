import React, {Component} from 'react';
import './css/homepage.css';
export default class Part7 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="section -flush-bottom">
          <div className="container _video">
            <div className="watch-video" data-ytid="euJAcVfn0C4">
              <aside>Let’s talk about how you talk to your customers.</aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
